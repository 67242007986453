import { useEffect, useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode.react";

function App() {
  const [urlInput, setUrlInput] = useState("");
  const [shortenedUrl, setShortenedUrl] = useState("Please set URL");
  const [copied, setCopied] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [countData, setCountData] = useState("");

  const apiKey = process.env.REACT_APP_API_KEY;
  const DomainKey = process.env.REACT_APP_DOMAIN;

  const handleUrlChange = (event) => {
    setUrlInput(event.target.value);
    setIsValid(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.hypershortener.com/~count");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setCountData(jsonData);
      } catch (error) {
        console.log("Something went wrong");
      }
    };

    fetchData();
  }, []);

  const shortenUrl = async () => {
    if (!urlInput) {
      // Handle the case when the input is empty
      setIsValid(false);
      setShortenedUrl("Please enter a valid URL");
      setShowResult(false); // Don't show result if input is empty
      return;
    }

    try {
      let isValidUrl;
      try {
        new URL(urlInput);
        isValidUrl = true;
      } catch (error) {
        isValidUrl = false;
      }

      if (!isValidUrl) {
        setIsValid(false); // Mark input as invalid
        setShortenedUrl("Please enter a valid URL");
        setShowResult(false); // Don't show result on invalid input
        return;
      }

      const response = await fetch(apiKey, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: urlInput, domain: DomainKey }),
      });

      if (!response.ok) {
        setShortenedUrl("Please enter a valid URL");
      } else {
        const data = await response.json();
        setShortenedUrl(data.url);
        setShowResult(true); // Only show result if URL is valid and shortened
      }
    } catch (error) {
      console.error("Error shortening URL:", error);
      setShortenedUrl("Please enter a valid URL");
      setShowResult(false); // Don't show result on error
    }
  };

  const toggleDisplay = () => {
    setShowQRCode(!showQRCode);
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shortenedUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 4000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <>
      <div className="MainSection">
        <div className="HeaderSection">
          <h1>HYPERSHORTENER</h1>
          <p>Your Go-To Link Shortener For</p>
          <p>All Your Marketing Needs!</p>
        </div>
        <div className="ContentSections">
          <div className="Inputs">
            <input
              className={`input ${!isValid ? "input-error" : ""}`} // Conditionally apply error styling
              type="text"
              value={urlInput}
              onChange={handleUrlChange}
              placeholder={
                !isValid ? "Please enter a valid URL" : "Enter full URL..."
              } // Change placeholder when invalid
              style={{
                borderColor: !isValid ? "red" : "", // Change border color if invalid
              }}
            />
            <button className="Button" onClick={shortenUrl}>
              GENERATE NEW LINK
            </button>

            {showResult && (
              <div className="btns">
                <div
                  className={`link hover-effect ${!showQRCode ? "active" : ""}`}
                  onClick={() => setShowQRCode(false)}
                >
                  Link
                </div>
                <div className="line"></div>
                <div
                  className="code hover-effect"
                  onClick={() => setShowQRCode(true)}
                >
                  QR Code
                </div>
              </div>
            )}

            {showResult && (
              <div className="resultbox">
                <div className={`result ${showResult ? "show" : ""}`}>
                  {showQRCode ? (
                    <div className="QRCode">
                      <QRCode value={shortenedUrl} size={200} level="M" />
                    </div>
                  ) : (
                    <h1
                      onClick={handleCopyClick}
                      className={`ShortenedURL ${
                        shortenedUrl === "Please enter a valid URL" ? "" : ""
                      }`}
                    >
                      {shortenedUrl}
                      {copied && (
                        <span className="tooltip">
                          <h3>Copied to Clipboard</h3>
                        </span>
                      )}
                    </h1>
                  )}
                </div>
                <div className="copyButton">
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="CopyIcon"
                    onClick={handleCopyClick}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className="Illustration"
            alt="Image by ucoyxmasayun on Freepik"
          ></div>
        </div>
        <div className="block">
          <div className="InfoBlock">
            <div className="Circle">{700 + countData.links30Days}</div>
            <p className="total">Last 30 Days</p>
          </div>
          <div className="InfoBlock">
            <div className="Circle2">{2111 + countData.linksTotal}</div>
            <p className="total">Total</p>
          </div>
          <div className="InfoBlock">
            <div className="Circle">{2005 + countData.links1Year}</div>
            <p className="total">Last Year</p>
          </div>
        </div>
      </div>

      {/* FIRST DESIGN */}
      {/* <div className="MainSection">
        <div className="AmountSection">
          <div className="Block">
            <p className="Title">Last 30 Days</p>
            <p className="Number">{738 + countData.links30Days}</p>
          </div>
          <div className="Block">
            <p className="Title">Total</p>
            <p className="Number">{2111 + countData.linksTotal}</p>
          </div>
          <div className="Block">
            <p className="Title">Last Year</p>
            <p className="Number">{2111 + countData.links1Year}</p>
          </div>
        </div>
        <div className="LOGO"></div>

        <div className="SloganSection">
          <p>Shorten your links swiftly! 🚀</p>
        </div>

        <div className="InputSection">
          <input
            className="input"
            type="text"
            value={urlInput}
            onChange={handleUrlChange}
            placeholder="Enter full URL"
          />

          <button className="GenerateButton" onClick={shortenUrl}>
            Shortify
          </button>
        </div>

        {showResult && (
          <div className={`ResultSection flip-in`}>
            <h4>Your Shortlink</h4>

            <div className="ShortenedUrlContainer">
              <h1
                onClick={handleCopyClick}
                className={`ShortenedURL ${
                  shortenedUrl === "Please enter a valid URL" ? "" : "bounce-in"
                }`}
              >
                {shortenedUrl}
              </h1>
              <FontAwesomeIcon
                icon={faCopy}
                className="CopyIcon"
                onClick={handleCopyClick}
              />
            </div>

            {copied && (
              <span className="tooltip">
                <h3>Copied to Clipboard</h3>
              </span>
            )}
          </div>
        )}
      </div> */}
    </>
  );
}

export default App;
